                                                                     
import seoSetting from '../../assets/images/seo_setting.png';
import seoSettingActive from '../../assets/images/seo_active.png';
import brush from '../../assets/images/brush.png';
import brushActive from '../../assets/images/brush_active.png';
import typography from '../../assets/images/typography.png';
import typographyActive from '../../assets/images/typographyactive.png';
import analytic from '../../assets/images/analytics.png';
import analyticActive from '../../assets/images/analyticactive.png';
import integration from '../../assets/images/integration.png';
import billing from '../../assets/images/billing.png';
import billingActive from '../../assets/images/billingactive.png';
import user from '../../assets/images/user.png';
import userActive from '../../assets/images/useractive.png';
import Image from 'react-bootstrap/Image';

function LeftNav({loadPage}){

	return(
		<>
			
			
			<div className="left_nav_section">
				<ul>
					<li className="active">
						<a  onClick={() => loadPage('seo')} >
						<Image
							className="img_icon"
			              	src={seoSetting}
			            />
			            <Image
							className="img_active"
			              	src={seoSettingActive}
			            />
            			General SEO Settings</a>
					</li>

					<li>
						<a  onClick={() => loadPage('branding')}>
						<Image
							className="img_icon"
			              	src={brush}
			            />
			            <Image
							className="img_active"
			              	src={brushActive}
			            />
            			Branding</a>
					</li>

					<li>
						<a  onClick={() => loadPage('typography')}>
						<Image
							className="img_icon"
			              	src={typography}
			            />
			            <Image
							className="img_active"
			              	src={typographyActive}
			            />
            			Typography</a>
					</li>

					<li>
						<a  onClick={() => loadPage('analytic')}>
						<Image
							className="img_icon"
			              	src={analytic}
			            />
			            <Image
							className="img_active"
			              	src={analyticActive}
			            />
            			Analytics</a>
					</li>


					<li>
						<a  onClick={() => loadPage('integration')}>
						<Image
							className="img_icon"
			              	src={integration}
			            />
			            <Image
							className="img_active"
			              	src={analyticActive}
			            />
            			Integrations</a>
					</li>

					
					<li>
						<a  onClick={() => loadPage('tags')}>
						<Image
							className="img_icon"
			              	src={user}
			            />
			            <Image
							className="img_active"
			              	src={userActive}
			            />
            			Customer Tags</a>
					</li>

					

					<li>
						<a  onClick={() => loadPage('user')}>
						<Image
							className="img_icon"
			              	src={user}
			            />
			            <Image
							className="img_active"
			              	src={userActive}
			            />
            			Users</a>
					</li>
				</ul>
			</div>
		</>
	)
}

export default LeftNav;