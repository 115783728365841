import React,{useState,useEffect} from 'react';
import App from './App';
import 'assets/scss/App.scss';
import 'assets/css/style.css';
import { createRoot } from 'react-dom/client';
import { StoryStatusProvider, useStoryStatus } from './app/storyStatus';
import useIndexedDBMedia from './app/IndexedDBMedia/useIndexedDBMedia';

const container = document.getElementById('root');
const root = createRoot(container);
import { BrowserRouter,useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fetchWrapper } from './components/helpers';
import moment from 'moment';
import useDocumentTitle from "./useDocumentTitle"
const AppInital = () => {


  const navigate = useNavigate();


  const backendUrl = process.env.REACT_APP_API_URL
 const [loader, setLoader] = useState(false);
  const checkDomain = async () => {
    
     const url = `${backendUrl}/tenent/check-domain`;

     const subdomain =  window.location.host.split('.')[0];

    

     

      const data  = await fetchWrapper.post(url,{domain:subdomain})

      if(!data.data){
       
           navigate('/domain-error')
      }else{
        localStorage.setItem("tenent", JSON.stringify(data.data));
        const userData = data.data


         

        const createDate = moment(userData.created_at).format('YYYY-MM-DD');

    

          let expireDate = moment(createDate).add(14, 'days');


            var a = moment();
            
            const trial = expireDate.diff(a, 'days')   // =1
            
            if(trial < 0 && userData.payment_status != 'active' && localStorage.getItem('user')){
              // navigate('/expire');
            }
      }
    



  
}

  useEffect(() => {
    if(window.location.pathname != '/error'){
        checkDomain();

    }
    
  }, []);
  
  useIndexedDBMedia();
  const { isInitializingIndexDB, isRefreshingMedia } = useStoryStatus(
    ({ state }) => ({
      isInitializingIndexDB: state.isInitializingIndexDB,
      isRefreshingMedia: state.isRefreshingMedia,
    })
  );

  const AppContainer = styled.div`
  height: ${({ isMobile }) => {
    return isMobile ? window?.innerHeight + 'px' : '100vh';
  }};
`;
const tenentData = localStorage.getItem('tenent')
let title = '';
if(localStorage.getItem('tenent')){
   title = JSON.parse(tenentData).company
}
useDocumentTitle(title + " Woosh Stories")

  return  (
  
   <AppContainer isMobile={window.matchMedia('(max-width: 480px)').matches}>
    <App />
    
    </AppContainer>
  
  ) 
};
 
root.render(
  
	<StoryStatusProvider>
    <BrowserRouter>

  	   <AppInital />
    </BrowserRouter>
	</StoryStatusProvider>
  

	);
